// import {  enqueueSnackbar } from 'notistack'
import { QueryClient } from '@tanstack/react-query'

function queryErrorHandler (error) {
  const title = error instanceof Error ? error.message : 'Error al conectar con servidor'

  console.warn(title)

  // enqueueSnackbar(title, {
  //   variant: 'error',
  //   persist: true,
  //   preventDuplicate: true
  // })
}

const queryClient = new QueryClient({})

export default queryClient
