// hooks
import useAuth from '../hooks/useAuth'
// utils
import createAvatar from '../utils/createAvatar'
//
import Avatar from './Avatar'

// ----------------------------------------------------------------------

export default function MyAvatar ({ ...other }) {
  const { user } = useAuth()
  return (
    <Avatar
      src={user?.photoURL}
      alt={user?.email}
      color={user?.photoURL ? 'default' : createAvatar(user?.email).color}
      {...other}
    >
      {createAvatar(user?.email).name}
    </Avatar>
  )
}
