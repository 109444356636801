import { createContext, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import jwtDecode from 'jwt-decode'

// utils
import { axiosInstance } from '../utils/axios'
import { isValidToken, setSession } from '../utils/jwt'
import api from '../utils/api'

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
}

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    }
  },
  LOGIN: (state, action) => {
    const { user } = action.payload

    return {
      ...state,
      isAuthenticated: true,
      user
    }
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload

    return {
      ...state,
      isAuthenticated: true,
      user
    }
  }
}

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state)

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => {},
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
})

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node
}

function AuthProvider ({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken')
        const refreshToken = window.localStorage.getItem('refreshToken')
        const email = window.localStorage.getItem('email')

        // const jwtData = jwtDecode(accessToken)

        if (refreshToken && isValidToken(refreshToken)) {
          setSession(accessToken, refreshToken)

          // TODO: Obtener de API
          // const response = await axiosInstance.get(`/whoami/${jwtData.user_id}/`)

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user: {
                displayName: email,
                email
              }
            }
          })
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          })
        }
      } catch (err) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        })
      }
    }

    initialize()
  }, [])

  const login = async (email, password) => {
    const { data } = await api.auth.login({
      email,
      password
    })

    const { access, refresh } = data

    window.localStorage.setItem('email', email)

    if (access && refresh) {
      setSession(access, refresh)
    }

    dispatch({
      type: 'LOGIN',
      payload: {
        user: {
          displayName: data.email,
          email: data.email
        }
      }
    })
    return data
  }

  const register = async (email, password, firstName, lastName) => {
    const response = await axiosInstance.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName
    })
    const { accessToken, user } = response.data

    window.localStorage.setItem('accessToken', accessToken)
    window.localStorage.setItem('user', JSON.stringify(user))
    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    })
  }

  const logout = async () => {
    setSession(null, null)
    dispatch({ type: 'LOGOUT' })
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
