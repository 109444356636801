// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  header: {
    hello: 'Hello'
  },
  buttons: {
    create: 'Register',
    delete: 'Delete',
    update: 'Update',
    register: 'Register',
  },
  operations: 'Operations',
  event: 'Event',
  events: 'Events',
  phones: {
    M: "Mobile",
    W: "Work",
    H: "Home",
    O: "Other"
  },
  emails: {
    M: "Mobile",
    W: "Work",
    H: "Home",
    O: "Other"
  },
  addresses: {
    M: "Móvil",
    W: "Trabajo",
    H: "Casa",
    O: "Otro"
  }
}

export default en
