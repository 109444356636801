import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
// @mui
import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import { Icon } from '@iconify/react'
//
import Image from '../Image'
import RejectionFiles from './RejectionFiles'
import BlockContent from './BlockContent'

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  overflow: 'hidden',
  position: 'relative',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('padding'),
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' }
}))

// ----------------------------------------------------------------------

UploadSingleFile.propTypes = {
  error: PropTypes.bool,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  helperText: PropTypes.node,
  sx: PropTypes.object
}

export default function UploadSingleFile ({ error = false, file, helperText, sx, ...other }) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    ...other
  })

  const getFilePreview = (file) => {

    const fileType = file.path.split('.').pop()

    switch (fileType) {
      case 'pdf':
        return 'https://www.grackledocs.com/wp-content/uploads/2023/09/pdf.png'    
      default:
        return file.preview
    }
  }

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter'
          }),
          ...(file && {
            padding: '12% 0'
          })
        }}
      >
        <input {...getInputProps()} />

        <BlockContent />

        {file && (
          <Image
            alt='file preview'
            src={typeof file === 'string' ? file : getFilePreview(file)}
            sx={{
              top: 8,
              left: 8,
              borderRadius: 1,
              position: 'absolute',
              width: 'calc(100% - 16px)',
              height: 'calc(100% - 16px)'
            }}
          />
        )}
      </DropZoneStyle>
      {
        file &&
        <Box display="flex" alignItems="center" justifyContent="center" mt={2} >
          <Icon icon="solar:file-bold-duotone" width={20} color='blue' />
          <Typography variant='h6' ml={1} >{file?.path}</Typography>
        </Box>
      }

      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}

      {helperText && helperText}
    </Box>
  )
}
