import { useQuery } from '@tanstack/react-query'
import api from '../../../../utils/api'

const PAGE_SIZE = 48
const queryKey = 'tickets'
const getAllOnGoingTickets = (params) => api.tickets.getAllOngoing(params);
const getAllTickets = (params) => api.tickets.filter(params);
const getTicket = (id) => api.tickets.getBySlug(id)

export const settings = {
  getAllTickets,
  getTicket,
  PAGE_SIZE,
  queryKey,
}

// Get items
export const useListTickets = (params) => (
  {
    ...useQuery([queryKey, params], async () => {
      const res = await getAllTickets(params)
      return res.data
    }),
  }
)
export const useGetTicketCountEvery5m = () => {  
    const res = useQuery([queryKey], async () => {
      const res = await getAllTickets({ available: true, page: 1 })
      return res.data
    }, { refetchInterval: 5 * 60 * 1000 });

    return res?.data?.count ?? 0
}

export const useGetTicket = (id) => (
  {
    ...useQuery([queryKey, id], async () => {
      const res = await getTicket(id)
      return res.data
    }),
  }
)

export const useListOngoingTickets = (params) => (
  {
    ...useQuery([queryKey, params], async () => {
      const res = await getAllOnGoingTickets(params)
      return res.data
    }),
  }
)