import * as Sentry from '@sentry/react';
import { useRoutes } from 'react-router-dom';

Sentry.init({
  // dsn: 'https://2f6277e3b20ce5bf98532fc2d2343045@o1084450.ingest.us.sentry.io/4506861015728128', // PROD C18
  dsn: 'https://577df0939530b5c3d98311b414096847@o4506864787062784.ingest.us.sentry.io/4506864793157632', // DEV SEBASTIAN
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
});

const useSentryRoutes = Sentry.wrapUseRoutes(useRoutes);

export default useSentryRoutes;

// ----------------------------------------------------------------------

// export function FallbackUI({ error, componentStack, resetError }) {

//   return (
//     <div style={{ margin: "50px" }}>
//       <div style={{ textAlign: 'center', alignItems: 'center', marginTop: "50px" }}>
//         <div>
//           <div>{error.toString()}</div>
//         </div>

//         <div>
//           <div>{componentStack}</div>
//         </div>

//         <div>
//           <h4 style={{ color: 'black', textAlign: "center" }}>
//             Oops, se ha encontrado un error.
//             <br />
//             Este error se ha registrado y se corregira pronto.
//           </h4>
//         </div>

//         <div>
//           <SeverErrorIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
//         </div>

//         <button onClick={() => resetError()}>
//           Click here to reset!
//         </button>
//       </div>
//     </div>
//   )
// }
