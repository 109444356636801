import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router'
// routes
import { PATH_DASHBOARD } from '../routes/paths'
// components
import Iconify from '../components/Iconify'
import { useApprovalsStore } from '../store/useApprovalsStore'
import { useGetTicketCountEvery5m } from '../pages/dashboard/tickets/hooks/useTickets'

export const useNavItems = () => {
  const count = useGetTicketCountEvery5m()
  const { pathname } = useLocation()
  const [badgeCount, setBadgeCount] = useState(0)

  const CustomNavItems = {
    clientes: [
      {
        subheader: '',
        items: [
          { title: 'Home', path: PATH_DASHBOARD.acreditados.home, icon: <Iconify icon='ant-design:home-filled' /> },
          { 
            title: 'pase a crédito', 
            path: PATH_DASHBOARD.acreditados.approvals, 
            icon: <Iconify icon={'material-symbols:fact-check-outline-rounded'}/>,
            badge: count,
          },
        ]
      },
      // DASHBOARD
      // ----------------------------------------------------------------------
      {
        subheader: 'Dashboard',
        items: [
          {
            title: 'Dashboard',
            path: PATH_DASHBOARD.dashboard.root,
            icon: <Iconify icon={'bxs:bar-chart-alt-2'} />,
          },
        ],
      },
      // CONVERSACIONES
      // ----------------------------------------------------------------------
      {
        subheader: 'Conversaciones',
        items: [
          {
            title: 'Conversaciones',
            path: PATH_DASHBOARD.conversations.root,
            icon: <Iconify icon={'bx:chat'} />,
          },
        ],
      },
      // CLIENTES
      // ----------------------------------------------------------------------
      {
        subheader: 'clientes',
        items: [
          { title: 'solicitudes', path: PATH_DASHBOARD.acreditados.requests, icon : <Iconify icon={'carbon:request-quote'}/>},
          { title: 'clientes', path: PATH_DASHBOARD.acreditados.clients, icon : <Iconify icon={'mdi:user-check'}/>},
          // { title: 'prestamos', path: PATH_DASHBOARD.loans, icon : <Iconify icon={'ic:outline-request-page'}/>},
          { title: 'nips', path: PATH_DASHBOARD.acreditados.nips, icon : <Iconify icon={'ph:key-bold'}/>},
          {
            title: 'reportes',
            path: PATH_DASHBOARD.acreditados.reports.root,
            icon: <Iconify icon="carbon:report" />,
            children: [
              // { title: 'NIPs', path: PATH_DASHBOARD.acreditados.reports.nips },
              { title: 'Solicitudes', path: PATH_DASHBOARD.acreditados.reports.requests },
              { title: 'Pases a credito', path: PATH_DASHBOARD.acreditados.reports.goToCredit },
              { title: 'Circulo de crédito', path: PATH_DASHBOARD.acreditados.reports.credit },
            ],
          },
          {
            title: 'cobranza',
            path: PATH_DASHBOARD.acreditados.debtColletion.root,
            icon: <Iconify icon="game-icons:pay-money" />,
            disabled: true,
            children: [
              { title: 'crear Nueva', path: PATH_DASHBOARD.acreditados.debtColletion.newCampaign },
              { title: 'campañas', path: PATH_DASHBOARD.acreditados.debtColletion.campaigns.root },
            ],
          },
        ]
      },
      // OTROS MODULOS
      {
        subheader: 'otros modulos',
        items: [
          { title: 'tiendas', path: PATH_DASHBOARD.stores.root, icon: <Iconify icon='material-symbols:store' />, cy: "modulo-tiendas-nav-item", disabled: true }
        ]
      }
    ],
    tiendas: [
      {
        subheader: '',
        items: [
          { title: 'tiendas', path: PATH_DASHBOARD.stores.root, icon: <Iconify icon='material-symbols:store' />, cy: "modulo-tiendas-nav-item" },
          { title: 'usuarios', path: PATH_DASHBOARD.stores.users, icon: <Iconify icon='mdi:users' />, cy: "modulo-tiendas-nav-item" }
        ]
      },
      {
        subheader: 'otros modulos',
        items: [
          { title: 'clientes', path: PATH_DASHBOARD.acreditados.home, icon: <Iconify icon='mdi:user' />, cy: "modulo-tiendas-nav-item" }
        ]
      }
    ]
  }
  const [navItems, setNavItems] = useState(CustomNavItems.clientes)

  useEffect(() => {
    if (pathname.includes('/acreditados')) {
      setNavItems(CustomNavItems.clientes)
    } else if (pathname.includes('/tiendas')) {
      setNavItems(CustomNavItems.tiendas)
    }
  }, [pathname, count])

  return { navItems }
}