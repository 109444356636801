import { Suspense, lazy } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
// layouts
import DashboardLayout from '../layouts/dashboard'
import LogoOnlyLayout from '../layouts/LogoOnlyLayout'
// guards
import GuestGuard from '../guards/GuestGuard'
import AuthGuard from '../guards/AuthGuard'
// config
import { PATH_AFTER_LOGIN } from '../config'
import useSentryRoutes from "../sentry-config"
// components
import LoadingScreen from '../components/LoadingScreen'
import { PermissionGuard } from '../components/permissions/PermissionGuard'
import { PATH_DASHBOARD } from './paths'

// ----------------------------------------------------------------------

const Loadable = (Component) => ({ permissionAccepted, ...props }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation()
  const permissions = [100, 101, 102]

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <PermissionGuard
        permissionAccepted={permissionAccepted}
        permissions={permissions}
        acceptedComponent={<Component {...props} />}
        rejectedComponent={<Page403 />}
      />
    </Suspense>
  )
}

export default function Router() {
  return useSentryRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        { path: 'forgot-password', element: <ForgotPassword /> },
        { path: 'reset-password/:token', element: <ResetPassword /> },
        // { path: 'verify', element: <VerifyCode /> }
      ]
    },
    // History score
    {
      path: 'historial-crediticio/:id',
      element: (
        <AuthGuard>
          <HistoryScorePage />
        </AuthGuard>
      )
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'tiendas',
          children: [
            { element: <Navigate to={'/dashboard/tiendas/home'} replace />, index: true },
            { path: 'home', element: <StoresList /> },
            { path: 'usuarios', element: <UsersList /> }
          ]
        },
        {
          path: 'acreditados',
          children: [
            { element: <Navigate to={'/dashboard/home'} replace />, index: true },
            { path: 'home', element: <Home /> },
            {
              path: 'solicitudes',
              children: [
                { element: <ApplicationsList />, index: true },
                { path: ':id', element: <ApplicationDetail /> },
              ]
            },
            {
              path: 'clientes',
              children: [
                { element: <ClientList />, index: true },
                { path: 'perfil-de-credito/:profileId', element: <CreditProfile /> },
                { path: 'linea-de-credito/:creditId', element: <CreditDetails /> },
                {
                  path: ':id',
                  element: <ClientWrapper />,
                  children: [
                    { path: 'perfil-de-credito/:profileId', element: <CreditProfile /> },
                    { path: 'linea-de-credito/:creditId', element: <CreditDetails /> },
                    {
                      path: '',
                      element: <ClientDetail />,
                      children: [
                        { element: <GeneralSection />, index: true },
                        { path: 'creditos', element: <CreditsSection /> },
                        { path: 'creditos/:creditId', element: <CreditLineDetails /> },
                        { path: 'vencimientos', element: <Expireds /> },
                        { path: 'documentos', element: <DocsSection /> },
                      ]
                    },
                  ],
                },
              ]
            },
            {
              path: 'aprobaciones',
              children: [
                { path: '', element: <TicketList /> },
                { path: ':id', element: <TicketDetail /> },
              ]
            },
            { path: 'nips', element: <NipsList /> },
            {
              path: 'reportes',
              children: [
                { element: <ApplicationsReports />, path: 'solicitudes' },
                { element: <GoToCreditReports />, path: 'pases-a-credito' },
                { element: <CreditConsultReports />, path: 'circulo' },
              ]
            },
            {
              path: 'cobranza',
              children: [
                { element: <CollectionDebtList />, path: 'nueva-campaña' },
                {
                  path: 'campañas',
                  children: [
                    { element: <CollectionHistoryList />, index: true},
                    { path: ":id", element: <CollectionDetail /> },
                  ]
                },
              ]
            },
          ]
        },
        {
          path: 'conversaciones',
          children: [
            {
              element: <ConversationsList />,
              index: true,
            },
            {
              path: ':id',
              element: <ConversationItem />
            }
          ],
        },
        {
          path: 'metricas',
          children: [
            {
              element: <DashboardMetrics />,
              index: true,
            },
          ],
        },
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to='/404' replace /> }
      ]
    },
    {
      path: '/',
      element: <AuthGuard><DashboardLayout /></AuthGuard>,
      children: [
        { element: <Navigate to={PATH_DASHBOARD.root} />, index: true },
      ]
    },
    { path: '*', element: <Navigate to='/404' replace /> }
  ])
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')))
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')))
const ForgotPassword = Loadable(lazy(() => import('../pages/auth/ForgotPassword')))
// const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')))

// DASHBOARD
const Home = Loadable(lazy(() => import('../pages/dashboard/home/Home')))
const ClientList = Loadable(lazy(() => import('../pages/dashboard/clients/ClientList')))
const ClientWrapper = Loadable(lazy(() => import('../pages/dashboard/clients/ClientWrapper')))
const Expireds = Loadable(lazy(() => import('../pages/dashboard/clients/details/expireds/Expireds')))
const CreditProfile = Loadable(lazy(() => import('../pages/dashboard/clients/details/creditProfile/CreditProfile')))
const CreditDetails = Loadable(lazy(() => import('../pages/dashboard/clients/details/credits/CreditDetails')))
const ClientDetail = Loadable(lazy(() => import('../pages/dashboard/clients/details/ClientDetail')))
const CreditsSection = Loadable(lazy(() => import('../pages/dashboard/clients/details/credits/CreditsSection')))
const CreditLineDetails = Loadable(lazy(() => import('../pages/dashboard/clients/details/credits/CreditLineDetails')))
const GeneralSection = Loadable(lazy(() => import('../pages/dashboard/clients/details/general/GeneralSection')))
const DocsSection = Loadable(lazy(() => import('../pages/dashboard/clients/details/docs/DocsSection')))
const ApplicationsList = Loadable(lazy(() => import('../pages/dashboard/applications/ApplicationsList')))
const ApplicationDetail = Loadable(lazy(() => import('../pages/dashboard/applications/ApplicationDetail')))
const StoresList = Loadable(lazy(() => import('../pages/dashboard/stores/storesList/StoresList')))
const UsersList = Loadable(lazy(() => import('../pages/dashboard/stores/usersList/UsersList')))
const CollectionDebtList = Loadable(lazy(() => import('../pages/dashboard/debtCollection/CollectionDebtList')))
const CollectionHistoryList = Loadable(lazy(() => import('../pages/dashboard/debtCollection/CollectionHistoryList')))
const CollectionDetail = Loadable(lazy(() => import('../pages/dashboard/debtCollection/CollectionDetail')))

const TicketList = Loadable(lazy(() => import('../pages/dashboard/tickets/TicketList')))
const TicketDetail = Loadable(lazy(() => import('../pages/dashboard/tickets/details/TicketDetail')))
const ApplicationsReports = Loadable(lazy(() => import('../pages/dashboard/reports/ApplicationsReports')))
const GoToCreditReports = Loadable(lazy(() => import('../pages/dashboard/reports/GoToCreditReports')))
const CreditConsultReports = Loadable(lazy(() => import('../pages/dashboard/reports/CreditConsultReports')))
const NipsList = Loadable(lazy(() => import('../pages/dashboard/nips/NipsList')))
const HistoryScorePage = Loadable(lazy(() => import('../pages/dashboard/historyScorePage/HistoryScorePage')))

const ConversationsList = Loadable(lazy(() => import('../pages/dashboard/conversations/conversationsList')));
const ConversationItem = Loadable(lazy(() => import('../pages/dashboard/conversations/conversationItem')));

const DashboardMetrics = Loadable(lazy(() => import('../pages/dashboard/metrics/dashboardMetrics')))

// MAIN
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')))
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')))
const Page500 = Loadable(lazy(() => import('../pages/Page500')))
const Page403 = Loadable(lazy(() => import('../pages/Page403')))
const Page404 = Loadable(lazy(() => import('../pages/Page404')))
