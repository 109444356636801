import * as Yup from 'yup'
import { useState } from 'react'
import { useSnackbar } from 'notistack'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
// form
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
// @mui
import { styled } from '@mui/material/styles'
import { LoadingButton } from '@mui/lab'
import { Box, Card, Stack, Link, Alert, IconButton, Container, Typography, InputAdornment } from '@mui/material'
// routes
import { PATH_AUTH } from '../../routes/paths'
// hooks
import useAuth from '../../hooks/useAuth'
import useResponsive from '../../hooks/useResponsive'
import useIsMountedRef from '../../hooks/useIsMountedRef'
// components
import Page from '../../components/Page'
import Logo from '../../components/Logo'
import Image from '../../components/Image'
import Iconify from '../../components/Iconify'
import { FormProvider, RHFTextField } from '../../components/hook-form'
import { APP_NAME, LOGIN_IMAGE } from '../../config'


// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}))

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7)
  }
}))

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}))

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0)
}))

// ----------------------------------------------------------------------

export default function Login () {
  const { method } = useAuth()

  const smUp = useResponsive('up', 'sm')

  const mdUp = useResponsive('up', 'md')

  return (
    <Page title='Login'>
      <RootStyle>
        <HeaderStyle>
          <Logo />
          <Typography variant='body2' sx={{ mt: { md: -2 } }}>
              PANEL MESA DE CONTROL
          </Typography>
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <Typography variant='h3' sx={{ px: 5, mt: 10, mb: 5 }}>
              Hola, de nuevo!
            </Typography>
            <Image visibleByDefault disabledEffect src={"/assets/illustrations/illustration_register.png"} alt='login' />
          </SectionStyle>
        )}

        <Container maxWidth='sm'>
          <ContentStyle>
            <Stack direction='row' alignItems='center' sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant='h4' gutterBottom>
                  {`Ingresar a ${APP_NAME}`}
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>Introduzca sus datos a continuación.</Typography>
              </Box>
            </Stack>
            <LoginForm />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  )
}


// -------------------------------------------------------------------


function LoginForm () {
  const navigate = useNavigate()
  const { login, logout } = useAuth()
  const { enqueueSnackbar } = useSnackbar()

  const isMountedRef = useIsMountedRef()

  const [showPassword, setShowPassword] = useState(false)

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('El correo no es valido').required('Correo requerido').trim(),
    password: Yup.string().required('Contraseña requerida')
  })

  const defaultValues = {
    email: '',
    password: '',
    remember: true
  }

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues
  })

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = methods

  const onSubmit = async (data) => {
    try {
      await login(data.email, data.password)
        .then((data) => {
          navigate('/')
        })
        .catch(() => {
          enqueueSnackbar('Información no válida', {variant: 'error'})
        })
    } catch (error) {
      enqueueSnackbar('Algo salio mal, vuelva a intentarlo', {variant: 'error'})

      reset()

      if (isMountedRef.current) {
        setError('afterSubmit', { ...error, message: error.message })
      }
    }
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity='error'>{errors.afterSubmit.message}</Alert>}

        <RHFTextField data-cy="email" name='email' label='Email address' />

        <RHFTextField
          name='password'
          label='Password'
          data-cy="password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton onClick={() => setShowPassword(!showPassword)} edge='end'>
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </Stack>

      <Stack direction='row' alignItems='center' justifyContent='center' sx={{ my: 3 }}>
        {/* <RHFCheckbox name='remember' label='Recordar Inicio de Session' /> */}
        <Link component={RouterLink} variant='subtitle2' to={PATH_AUTH.forgotPassword}>
          ¿Se te olvidó tu contraseña?
        </Link>
      </Stack>

      <LoadingButton data-cy="login-submit" fullWidth size='large' type='submit' variant='contained' loading={isSubmitting}>
        Acceso
      </LoadingButton>
    </FormProvider>
  )
}