// ----------------------------------------------------------------------

export default function Button (theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            boxShadow: 'none'
          }
        },
        sizeLarge: {
          height: 48
        },
        // contained
        containedInherit: {
          color: theme.palette.grey[800],
          boxShadow: theme.customShadows.z8,
          '&:hover': {
            backgroundColor: theme.palette.grey[400]
          }
        },
        containedPrimary: {
          boxShadow: theme.customShadows.primary
        },
        containedSecondary: {
          boxShadow: theme.customShadows.secondary
        },
        containedInfo: {
          boxShadow: theme.customShadows.info
        },
        containedSuccess: {
          boxShadow: theme.customShadows.success
        },
        containedWarning: {
          boxShadow: theme.customShadows.warning
        },
        containedError: {
          boxShadow: theme.customShadows.error
        },
        // outlined
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey[500_32]}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover
          }
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover
          }
        }
      },
      variants: [
        {
          props: {color: 'disabled'},
          style: {
            color: theme.palette.action.disabled,
            boxShadow: 'none',
            
          }
        },
        {
          props: { color: 'disabled', variant: 'contained' },
          style: { 
            border: 'transparent !important',
            backgroundColor: theme.palette.action.disabledBackground,
          }
        },
        {
          props: { color: 'disabled', variant: 'outlined' },
          style: { 
            borderColor : theme.palette.action.disabledBackground,
            '&:hover': {
              backgroundColor: 'transparent',
              border: `solid 1px ${theme.palette.action.disabledBackground}`,
              cursor: 'default'
            }
          }
        }
      ]
    }
  }
}
