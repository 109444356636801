import Pusher from 'pusher-js';
import { createContext, useEffect, useState } from 'react'
// hooks
import useAuth from '../hooks/useAuth'

// ----------------------------------------------------------------------

const SocketContext = createContext({
  pusher: null,
  pusherReports: null,
})

// ----------------------------------------------------------------------

function SocketProvider ({ children }) {
  const { isAuthenticated } = useAuth()

  const [pusher, setPusher] = useState(null)

  useEffect(() => {
    if(!isAuthenticated) return

    const pusherInstance = new Pusher( process.env.REACT_APP_PUSHER_KEY, {
      cluster: 'us2'
    })

    setPusher(pusherInstance)
  }, [isAuthenticated])

  return (
    <SocketContext.Provider value={{ pusher }}>
      {children}
    </SocketContext.Provider>
  )
}

export { SocketContext, SocketProvider }
