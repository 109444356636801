import axios from 'axios';
import { axiosInstance, axiosSgcInstance } from './axios';

const ADMIN_ROOT = '/api/v1/control';
const SIMPLE_ROOT = '/api/v1';

export default {
  auth: {
    login(credentials) {
      return axiosInstance.post('/api/v1/auth/', credentials);
    },
    async refresh(refresh) {
      return axiosInstance.post('/api/v1/auth/refresh/', { refresh });
    },
    async reset(data) {
      return axiosInstance.post('/api/v1/users/reset/', data);
    },
    async confirm(data) {
      return axiosInstance.post('/api/v1/users/confirm/', data);
    },
  },
  chatbot: {
    history(convesationId) {
      return axiosInstance.get(`${process.env.REACT_APP_CHATBOT_API}api/v1/conversations/${convesationId}/`);
    },
    sendMessage(convesationId, data) {
      return axiosInstance.post(
        `${process.env.REACT_APP_CHATBOT_API}api/v1/conversations/${convesationId}/send_message/`,
        data
      );
    },
  },
  reports: {
    credit_score: {
      exports: {
        getAll(params) {
          return axiosInstance.get(`${ADMIN_ROOT}/creditscoreconsultexports/`, { params });
        },
        getBySlug(uuid) {
          return axiosInstance.get(`${ADMIN_ROOT}/creditscoreconsultexports/${uuid}/`);
        },
        create(params) {
          return axiosInstance.post(`${ADMIN_ROOT}/creditscoreconsultexports/`, params);
        },
      },
    },

    applications: {
      exports: {
        getAll(params) {
          return axiosInstance.get(`${ADMIN_ROOT}/applicationexports/`, { params });
        },
        getBySlug(uuid) {
          return axiosInstance.get(`${ADMIN_ROOT}/applicationexports/${uuid}/`);
        },
        create(params) {
          return axiosInstance.post(`${ADMIN_ROOT}/applicationexports/`, params);
        },
      },
    },

    tickets: {
      exports: {
        getAll(params) {
          return axiosInstance.get(`${ADMIN_ROOT}/creditanalystticketexports/`, { params });
        },
        getBySlug(uuid) {
          return axiosInstance.get(`${ADMIN_ROOT}/creditanalystticketexports/${uuid}/`);
        },
        create(params) {
          return axiosInstance.post(`${ADMIN_ROOT}/creditanalystticketexports/`, params);
        },
      },
    },

    credit(uuid) {
      return axiosInstance.get(`${ADMIN_ROOT}/creditscoreconsults/${uuid}/`);
    },
    nips(params) {
      return axiosInstance.get(`${ADMIN_ROOT}/reports/nips/`, { params });
    },
    analyst(params) {
      return axiosInstance.get(`${ADMIN_ROOT}/reports/analyst/`, { params });
    },
  },
  profiles: {
    getBySlug(slug) {
      return axiosInstance.get(`${ADMIN_ROOT}/profiles/${slug}/`);
    },
  },
  clients: {
    getAll(params) {
      return axiosInstance.get(`${ADMIN_ROOT}/clients/`, { params });
    },
    filter(params) {
      return axiosInstance.get(`${ADMIN_ROOT}/clients/`, { params });
    },
    getClient(random_slug) {
      return axiosInstance.get(`${ADMIN_ROOT}/clients/${random_slug}/`);
    },
    updateClient(params, random_slug) {
      return axiosInstance.patch(`${ADMIN_ROOT}/clients/${random_slug}/`, params);
    },
    createEmail(params) {
      return axiosInstance.post(`${ADMIN_ROOT}/client-emails/`, params);
    },
    createReference(params) {
      return axiosInstance.post(`${ADMIN_ROOT}/references/`, params);
    },
    createPhone(params) {
      return axiosInstance.post(`${ADMIN_ROOT}/phones/`, params);
    },
    createAddress(params) {
      return axiosInstance.post(`${ADMIN_ROOT}/addresses/`, params);
    },
    deleteEmail(random_slug) {
      return axiosInstance.delete(`${ADMIN_ROOT}/client-emails/${random_slug}`);
    },
    deletePhone(random_slug) {
      return axiosInstance.delete(`${ADMIN_ROOT}/phones/${random_slug}`);
    },
    deleteAddress(random_slug) {
      return axiosInstance.delete(`${ADMIN_ROOT}/addresses/${random_slug}`);
    },
    deleteReference(random_slug) {
      return axiosInstance.delete(`${ADMIN_ROOT}/references/${random_slug}`);
    },
    editEmail(params, random_slug) {
      return axiosInstance.patch(`${ADMIN_ROOT}/client-emails/${random_slug}/`, params);
    },
    editReference(params, random_slug) {
      return axiosInstance.patch(`${ADMIN_ROOT}/references/${random_slug}/`, params);
    },
    editPhone(params, random_slug) {
      return axiosInstance.patch(`${ADMIN_ROOT}/phones/${random_slug}/`, params);
    },
    editAddress(params, random_slug) {
      return axiosInstance.patch(`${ADMIN_ROOT}/addresses/${random_slug}/`, params);
    },
    creditConsult(random_slug) {
      return axiosInstance.get(`${ADMIN_ROOT}/client-credit-consults/${random_slug}/`);
    },
    loanProfiles(random_slug) {
      return axiosInstance.get(`${ADMIN_ROOT}/clients/${random_slug}/loan_profiles/`);
    },
    allMessages(random_slug, page) {
      return axiosInstance.get(`${ADMIN_ROOT}/clients/${random_slug}/messages/`, { params: { page } });
    },
    unlockPhone(random_slug) {
      return axiosInstance.patch(`${ADMIN_ROOT}/contacts/${random_slug}/unlock/`);
    },
    creditLine(id) {
      return axiosInstance.get(`${ADMIN_ROOT}/creditlines/${id}/`);
    }
  },
  tickets: {
    getAllOngoing(params) {
      return axiosInstance.get(`${ADMIN_ROOT}/tickets/my_ongoing/`, { params });
    },
    getAll(params) {
      return axiosInstance.get(`${ADMIN_ROOT}/tickets/`, { params });
    },
    filter(params) {
      return axiosInstance.get(`${ADMIN_ROOT}/tickets/`, { params });
    },
    getBySlug(slug) {
      return axiosInstance.get(`${ADMIN_ROOT}/tickets/${slug}/`);
    },
    assign() {
      return axiosInstance.get(`${ADMIN_ROOT}/tickets/assign/`);
    },
    approveCredit(comment, tickedId) {
      return axiosInstance.post(`${ADMIN_ROOT}/tickets/${tickedId}/close/`, {
        determination: 'A',
        comments: comment,
      });
    },
    rejectTicket(comment, tickedId) {
      return axiosInstance.post(`${ADMIN_ROOT}/tickets/${tickedId}/close/`, {
        determination: 'D',
        comments: comment,
      });
    },
  },
  nips: {
    sgc: {
      getAll(params) {
        return axiosInstance.get(`${ADMIN_ROOT}/nips/`, { params });
      },
    },
    chatbot: {
      getAll(params) {
        return axiosInstance.get(`${process.env.REACT_APP_CHATBOT_API}api/v1/conversations/`, { params });
      },
    },
  },
  applications: {
    getAll(params) {
      return axiosInstance.get(`${ADMIN_ROOT}/applications/`, { params });
    },
    getBySlug(slug) {
      return axiosInstance.get(`${ADMIN_ROOT}/applications/${slug}/`);
    },
    profile(profileId) {
      return axiosInstance.get(`${ADMIN_ROOT}/applications/${profileId}/by_profile/`);
    },
  },
  applicationMedia: {
    create(form) {
      return axiosInstance.post(`${ADMIN_ROOT}/applicationmedia/`, form);
    },
  },
  conversations: {
    deactivate(id, email) {
      return axiosSgcInstance.patch(`/api/v1/sgcconversations/${id}/deactivate/`, { email });
    },
    reset(conversationId, stepId, email) {
      return axiosSgcInstance.patch(`/api/v1/sgcconversations/${conversationId}/reset/`, { email, step: stepId });
    },
    getById(id) {
      return axiosSgcInstance.get(`/api/v1/sgcconversations/${id}/`);
    },
    list: (phone, options) => {
      const params = {
        ...options,
      };
      if (!phone) {
        delete params.search;
      }
      return axiosSgcInstance.get(`/api/v1/sgcconversations/?${new URLSearchParams(params)}`);
    },
  },
  permissions: {
    getByEmail(email) {
      return axiosSgcInstance.get(`/api/v1/permissions/${email}`);
    },
  },
  signatures: {
    getClientSignature(params) {
        return axiosInstance.get(`${ADMIN_ROOT}/client-signatures`, { params });
    },
    deleteClientSignature( id, { message } ) {
        return axiosInstance.delete(`${ADMIN_ROOT}/client-signatures/${id}`, { data: { message } });
    },
    getEmployeeSignature(params) {
        return axiosInstance.get(`${ADMIN_ROOT}/employee-signatures`, { params });
    },
    deleteEmployeeSignature(id) {
        return axiosInstance.delete(`${ADMIN_ROOT}/employee-signatures/${id}`);
    },
},
  envelopes: {
    getAll(params){
      return axiosInstance.get(`${ADMIN_ROOT}/envelopes/`, { params });
    },
    getList(params){
      return axiosInstance.get(`${ADMIN_ROOT}/envelope-history/`, { params });
    }
  }
};