import axios from 'axios'
import api from './api'
import { isValidToken } from './jwt'
// config
import { CHATBOT_HOST_API, HOST_API, CHATBOT_API_KEY } from '../config'
// import useAuth from '../hooks/useAuth'

// ----------------------------------------------------------------------

export const axiosInstance = axios.create({
  baseURL: HOST_API,
})

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if ((error.response.status === 401 || error.response.status === 403) && error?.response?.data?.code === "token_not_valid") {
      try {
        const refreshToken = window.localStorage.getItem('refreshToken')
        const isValid = isValidToken(refreshToken)

        if (!refreshToken || !isValid) throw new Error('refreshToken not found') // if refreshToken is not found, redirect to login

        const { data } = await api.auth.refresh(refreshToken)

        if (data?.access) { window.localStorage.setItem('accessToken', data?.access) }
        if (data?.refresh) { window.localStorage.setItem('refreshToken', data?.refresh) }

        // Update the Authorization header with the new access token
        error.config.headers.Authorization = `Bearer ${data?.access}`;

        // Retry the original request with the new token
        return axiosInstance(error.config);
      } catch (refreshError) {
        window.localStorage.removeItem('refreshToken')
        window.localStorage.removeItem('accessToken')
        window.location.href = '/auth/login';
        throw refreshError;
      }
    }
    throw (error?.response?.data);
  }
)

export const axiosSgcInstance = axios.create({
  baseURL: CHATBOT_HOST_API,
  headers: {
    Authorization: `Token ${CHATBOT_API_KEY}`
  }
})