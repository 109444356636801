import React from 'react'

export const PermissionGuard = ({
  permissionAccepted,
  permissions, 
  acceptedComponent, 
  rejectedComponent = <></>
}) => {

  if ( permissions.includes(permissionAccepted) || !permissionAccepted ) return acceptedComponent

  return rejectedComponent

}
