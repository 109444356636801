// routes
import { PATH_DASHBOARD } from '../../routes/paths'
// components
import Iconify from '../../components/Iconify'
// import useLanguageVerbose from './useLanguageVerbose'

// Posible deprecated
export default function useNavConfig () {
  // const { data: dataLang } = useLanguageVerbose()

  const navConfig = [
    {
      subheader: '',
      items: [
        { title: 'Home', path: PATH_DASHBOARD.root, icon: <Iconify icon='ant-design:home-filled' /> },
        { 
          title: 'pase a crédito', 
          path: PATH_DASHBOARD.acreditados.approvals, 
          icon : <Iconify icon={'material-symbols:fact-check-outline-rounded'}/>,
        },
      ]
    },
    // CLIENTES
    // ----------------------------------------------------------------------
    {
      subheader: 'clientes',
      items: [
        { title: 'solicitudes', path: PATH_DASHBOARD.acreditados.requests, icon : <Iconify icon={'carbon:request-quote'}/>},
        { title: 'clientes', path: PATH_DASHBOARD.acreditados.clients, icon : <Iconify icon={'mdi:user-check'}/>},
        // { title: 'prestamos', path: PATH_DASHBOARD.loans, icon : <Iconify icon={'ic:outline-request-page'}/>},
        { title: 'nips', path: PATH_DASHBOARD.acreditados.nips, icon : <Iconify icon={'ph:key-bold'}/>},
        {
          title: 'reportes',
          path: PATH_DASHBOARD.acreditados.reports.root,
          icon: <Iconify icon="carbon:report" />,
          children: [
            { title: 'Nips', path: PATH_DASHBOARD.acreditados.reports.nips },
            { title: 'Solicitudes', path: PATH_DASHBOARD.acreditados.reports.requests },
            { title: 'Pases a crédito', path: PATH_DASHBOARD.acreditados.reports.tickets },
          ],
        },
        {
          title: 'cobranza',
          path: PATH_DASHBOARD.acreditados.debtColletion.root,
          icon: <Iconify icon="game-icons:pay-money" />,
          children: [
            { title: 'campañas', path: PATH_DASHBOARD.acreditados.debtColletion.newCampaign },
            { title: 'historial', path: PATH_DASHBOARD.acreditados.debtColletion.campaigns.root },
          ],
        },
      ]
    },
  ]
  return navConfig
}
