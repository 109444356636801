// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const es = {
  header: {
    hello: 'Hola'
  },
  buttons: {
    create: 'Crear',
    delete: 'Eliminar',
    update: 'Actualizar',
    register: 'Registrar',
  },
  operations: 'Operaciones',
  event: 'Evento',
  events: 'Eventos',
  phones: {
    M: "Móvil",
    W: "Trabajo",
    H: "Casa",
    O: "Otro"
  },
  emails: {
    M: "Móvil",
    W: "Trabajo",
    H: "Casa",
    O: "Otro"
  },
  addresses: {
    M: "Móvil",
    W: "Trabajo",
    H: "Casa",
    O: "Otro"
  }
}

export default es
