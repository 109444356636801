import "./styles/globalstyles.css"
// routes
import Router from './routes'
// theme
import ThemeProvider from './theme'
// components
import ThemeSettings from './components/settings'
import { ChartStyle } from './components/chart'
import ScrollToTop from './components/ScrollToTop'
import MotionLazyContainer from './components/animate/MotionLazyContainer'
// import GlobalSpinner from './components/GlobalSpinner'

// ----------------------------------------------------------------------

export default function App () {
  
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          {/* <GlobalSpinner /> */}
          <ChartStyle />
          <ScrollToTop />
          <Router />
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  )
}
